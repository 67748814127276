@import "../../shared.scss";

.navbar {
  position: fixed;
  width: 100%;
  background-color: $color-background;
  height: 60px;
  top: 0;
  left: 0;
  background: rgb(254, 195, 196);
  background: radial-gradient(circle, rgba(254, 195, 196, 0.075) 25%, $color-background-secondary 71%);
  z-index: 600;
  .navbar-navigation {
    display: flex;
    align-items: center;
    height: 100%;
    .navbar-navigation-items {
      a {
        text-decoration: none;
        color: $color-paragraph;
      }
      a:hover {
        color: $color-highlight;
      }
      a:active {
        color: $color-highlight;
      }
      ul {
        list-style: none;
        margin: 0;
        padding-right: 1rem;
        display: flex;
      }
      li {
        padding: 0 0.5rem;
      }
    }
    .spacer {
      flex: 1;
    }
  }
  .navbar-logo {
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: row;
    a {
      color: $color-headline;
      text-decoration: none;
      font-size: 1.5rem;
      padding: 0 1rem;
    }
    .logo{
      height: 100%;
      width: 50px;
    }
    h2{
      color: white;
      margin: auto;
    }
  }
}

@media (max-width: 768px) {
  .navbar-navigation-items {
    display: none;
  }
  .navbar .navbar-logo{
    a {
      font-weight: 900;
    }
  } 
}
