@import "../../shared.scss";

.page-tires {
  width: 100%;
  .container-header {
    position: relative;
    text-align: center;
    color: $color-headline;
    .header-image {
      width: 100%;
      height: 900px;
      object-fit: cover;
    }
    .centered {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      h1 {
        background-color: $color-background-secondary;
        width: 300px;
        height: 36px;
      }
    }
  }
  .container-main {
    .container {
      width: 80%;
      max-width: 1000px;
      overflow: hidden;
      background-color: $color-card-background;
      margin: 100px auto;
      border-radius: 5px;
      h1 {
        text-align: center;
        color: $color-headline;
      }
      .paragraph {
        padding: 30px;
        color: $color-paragraph-secondary;
        text-align: center;
      }
      .images {
        width: 100%;
        display: flex;
        justify-content: center;
        .i {
          object-fit: cover;
          width: 250px;
          height: 250px;
          padding: 10px;
        }
      }
      .buttons {
        display: flex;
        justify-content: space-evenly;
        .button {
          text-align: center;
          padding: 50px 0;
          a {
            text-decoration: none;
            color: $color-button;
            margin: 50px 20px;
            padding: 20px 20px;
            border-style: solid;
            border-width: 1px;
            .phone {
              position: relative;
              top: 5px;
              left: 0;
            }
            .fire {
              position: relative;
              top: 5px;
              left: 0;
            }
            .car {
              position: relative;
              top: 5px;
              left: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .container {
    .images {
      justify-content: between;
      flex-direction: column;
      .i {
        margin: 0 auto;
        padding: 0;
        width: 200px;
      }
    }
  }
  .mobile {
    display: none;
  }
}
