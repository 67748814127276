/* main colors */
$color-background: #fffffe;
$color-headline: #fffffe;
$color-paragraph: #abd1c6;
$color-button: #f9bc60;
$color-highlight: #f9bc60;
$color-button-text: #001e1d;

/* secondary colors */
$color-background-secondary: #0f3433;
$color-headline-secondary: #001e1d;
$color-subheadline-secondary: #0f3433;
$color-paragraph-secondary: #abd1c6;

/* card colors */
$color-card-background: #004643;
$color-card-background-transparent: #004643e5;
$color-card-heading: #fffffe;
$color-card-paragraph: #abd1c6;

