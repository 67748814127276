@import "../../shared.scss";

.footer {
  width: 100%;
  display: block;
  background-color: $color-background-secondary;
  .flexbox {
    display: flex;
    justify-content: space-around;
    padding: 50px 0;
    text-align: center;
    h3{
        color: $color-headline;
    }
    a{
        color: $color-paragraph;
        text-decoration: none;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        padding-bottom: 10px;
        list-style: none;
      }
    }
  }
  .images{
    display: flex;
    flex-direction: column;
  }
  .maps {
    margin: 0 auto;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.lomarengas{
  width: 200px;
}


@media (max-width: 769px){
  .flexbox {
    flex-direction: column;
    .box{
      padding: 20px 0;
    }
}
}