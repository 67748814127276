@import "../../shared.scss";

.page-home {
  width: 100%;
  .description {
    display: flex;
    flex-direction: row;
    width: 95%;
    max-width: 1000px;
    position: absolute;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      color: $color-headline;
    }
    .title {
      margin: 0 auto;
      font-size: 1.4rem;
      letter-spacing: 0.03rem;
    }
  }
  .right {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: absolute;
    font-size: 1.1rem;
    top: 800px;
    width: 100%;
    font-size: 30px;
    text-align: center;
    color: whitesmoke;
  }
  .container-header {
    position: relative;
    text-align: center;
    padding: 0;
    margin: 0;
    .header-image {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 900px;
      object-fit: cover;
      position: relative;
    }
    .centered {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
      .logo {
        width: 100px;
      }
    }
    .description {
      .clickable {
        text-decoration: none;
        color: $color-button;
        font-size: 1.2rem;
        margin: 20px;
      }
    }
  }
  .container-top {
    width: 100%;
    background-color: $color-card-background-transparent;
    padding: 0;
    margin: 0;
    h1 {
      text-align: center;
      padding: 20px;
      margin: 0;
      color: $color-headline;
    }
    .button {
      text-align: end;
      padding: 30px;
      .a {
        text-decoration: none;
        color: $color-button;
        margin: 50px 20px;
        padding: 10px 10px;
        border-style: solid;
        border-width: 1px;
        .phone {
          position: relative;
          top: 5px;
          left: 0;
        }
        .fire {
          position: relative;
          top: 5px;
          left: 0;
        }
        .car {
          position: relative;
          top: 5px;
          left: 0;
        }
      }
    }
    .flexbox {
      display: flex;
      width: 100%;
      padding-bottom: 0px;
      .paragraph {
        width: 80%;
        margin: auto;
        color: $color-paragraph;
        padding: 10px;
        font-size: 1.1rem;
        letter-spacing: 0.15rem;
        a {
          text-decoration: none;
          color: $color-button;
          margin: 30px 0;
          word-break: break-all;
        }
        hr {
          border-width: 0.6px;
          border-color: $color-headline;
          margin-right: 10%;
          left: 0;
        }
      }
    }
  }
  .image {
    width: 40%;
    min-width: 200px;
    padding: 0 30px;
    .img {
      width: 100%;
    }
  }
  .container {
    width: 80%;
    max-width: 1000px;
    overflow: hidden;
    background-color: $color-card-background;
    margin: 100px auto;
    border-radius: 5px;
    h1 {
      text-align: center;
      padding: 30px 0;
      color: $color-headline;
    }
    .button {
      text-align: end;
      padding: 30px;
      .a {
        text-decoration: none;
        color: $color-button;
        margin: 50px 20px;
        padding: 10px 10px;
        border-style: solid;
        border-width: 1px;
        .phone {
          position: relative;
          top: 5px;
          left: 0;
        }
        .fire {
          position: relative;
          top: 5px;
          left: 0;
        }
        .car {
          position: relative;
          top: 5px;
          left: 0;
        }
      }
    }
    .flexbox {
      display: flex;

      width: 100%;
      padding-bottom: 0px;
      .image {
        width: 40%;
        min-width: 200px;
        padding: 0 30px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .paragraph {
        width: 80%;
        margin: auto;
        color: $color-paragraph;
        padding: 10px;
        font-size: 1.3rem;
        letter-spacing: 0.15rem;
        a {
          text-decoration: none;
          color: $color-button;
          margin: 30px 0;
          word-break: break-all;
        }
        hr {
          border-width: 0.6px;
          border-color: $color-headline;
          margin-right: 10%;
          left: 0;
        }
      }
    }
  }
  .maps {
    margin: 50px auto;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 769px) {
  .page-home {
    .flexbox {
      flex-direction: column;
      .image {
        width: 100%;
        min-width: 200px;
        padding: 10px;
        margin: auto;
      }
      .paragraph {
        width: 100%;
      }
    }
  }
  .mobile {
    display: none;
  }
}
@media (max-width: 1300px) {
  .page-home {
    .right {
      top: 750px;
      flex-direction: column;
      font-size: 17px;
      p {
        margin: 5px;
      }
    }
  }
}
