@import '../../../shared.scss';

.toggle-button {
  height: 30px;
  width: 30px;
  background: transparent;
  border: none;
  margin: 0 2rem;
  padding: 0;
  .button-line {
    width: 100%;
    height: 3px;
    display: block;
    background-color: $color-button;
    margin-top: 5px;
  }
  :hover .button-line {
    background-color: $color-button-text;
  }
}

.toggle-button:focus {
    outline: 0;
  }

.navbar-mobile {
  height: 100%;
  background-color: $color-background-secondary;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.1s ease-out;
  ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  a {
    color: $color-paragraph;
    text-decoration: none;
    font-size: 1.4rem;
  }
  a:hover,
  a:active {
    color: $color-background;
  }
  li {
    margin: 0.5rem 0;
  }
}

.navbar-mobile.open {
    transform: translateX(0);
  }


@media (min-width: 769px){
    .toggle-button,
    .navbar-mobile{
        display: none;
    }
}